import React from "react";
import PropTypes from "prop-types";
import HowItWorks from "./HowItWorks";
import Footer from "./Footer";

class Lobby extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { data } = this.props;
        const connectedParticipants = Object.keys(data.participants).filter(key => data.participants[key].connected);
        return (
            <div>
                <div className="max-w-3xl mx-auto px-6 pb-20">
                    <div className="mt-6">
                        <p className="font-medium leading-none text-4xl text-black">Your group hasn't started yet.</p>
                        <p className="font-light leading-tight mt-2 text-lg text-gray-600">Sit tight for just a bit until your group starts.</p>
                    </div>

                    <div className="mt-12 p-8 shadow-2xl border">
                        <p className="uppercase font-semibold text-sm tracking-widest text-blue-500">{connectedParticipants.length} {connectedParticipants.length === 1 ? "participant" : "participants"} so far</p>
                        <div className="flex flex-wrap -py-1 -px-1 mt-2 w-full">
                            {connectedParticipants.map(key => {
                                const i = data.participants[key];
                                return (
                                    <div className="py-1 px-1 w-full md:w-1/5" key={"participant-" + key}>
                                        <div className="bg-gray-200 rounded px-3 py-2">
                                            <p className="text-gray-800 text-sm font-light">{i.displayName}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <p className="uppercase font-semibold tracking-widest text-blue-500 text-sm mt-8 mb-1">Your first prompts</p>
                        {data.prompts.map((prompt, i) => {
                            return (
                                <p className="font-light mt-2 text-gray-600"><span className="text-gray-400">{i+1}.</span> {prompt}</p>
                            );
                        })}
                    </div>



                    <div className="mt-16">
                        <p className="text-blue-500 font-light tracking-wide text">An insight into our platform.</p>
                        <p className="text-black font-semibold text-3xl">How it works – 3 simple steps</p>
                        <div className="mt-6">
                            <HowItWorks />
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

Lobby.propTypes = {
    data: PropTypes.object.isRequired
};

export default Lobby;