import React from 'react';

const Footer = () => {
    return ( 
        <div className="py-16 bg-gray-200">
            <div className="max-w-3xl mx-auto px-6">
                <p className="text-sm tracking-widest text-gray-600 font-bold text-center">&copy; LINKU 2020</p>
                <p className="text-sm text-gray-500 font text-center">Uniting communites through lasting friendships.</p>
            </div>
        </div>
     );
}
 
export default Footer;