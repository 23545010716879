import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import NoGroupAssigned from "./NoGroupAssigned";
import Contact from "./Contact";
import { auth } from "firebase";

class Break extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        const { data } = this.props;
        const myGroup = Object.keys(data.groups).find(key => data.groups[key].includes(this.props.user.uid));
        if (myGroup == undefined) {
            return (
                <NoGroupAssigned />
            );
        }
        let previousContacts = null;
        if (data.previousGroups.length > 0) {
            const oldGroups = data.previousGroups[data.previousGroups.length - 1];
            const myOldGroup = Object.keys(oldGroups).find(key => oldGroups[key].includes(this.props.user.uid));
            if (myOldGroup !== undefined) {
                previousContacts = oldGroups[myOldGroup].map(i => {
                    const info = data.participants[i];

                    if (i === this.props.user.uid) return null

                    return <Contact roomCode={this.props.roomCode} uid={i} contact={info} />
                });
            }
        }

        const nextRound = moment(data.nextRound.toDate());
        return (
            <div className="max-w-3xl mx-auto px-6" >
                <div className="mt-6">
                    <p className="text-4xl text-black leading-none font-medium">Break time!</p>
                    <p className="text-xl text-gray-500 font-light">Next group in {nextRound.toNow(true)}</p>
                </div>

                <div className="mt-8 p-8 border shadow-xl">
                    {
                        previousContacts == null ? null : (
                            <div>
                                <p className="font-medium text-2xl mb-3">Select the people you want to stay in touch with!</p>
                                <p className="uppercase font-semibold tracking-widest text-blue-500 mb-1">Your last group</p>
                            </div>
                        )
                    }
                    {previousContacts}

                    <p className="uppercase font-semibold tracking-widest text-blue-500 mt-8 mb-1">Your next prompts</p>
                    {data.prompts.map((prompt, i) => {
                        return (
                            <p className="font-light mt-2 text-gray-600"><span className="text-gray-400">{i + 1}.</span> {prompt}</p>
                        );
                    })}
                </div>
            </div>
        );
    }
}

Break.propTypes = {

};

export default Break;