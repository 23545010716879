import React from "react";
import { PuffLoader } from "react-spinners"
import PropTypes from "prop-types";

class Loader extends React.Component {

    render() {
        return (
            <div className="h-screen flex items-center justify-center">
                <PuffLoader size="100" color="#63b3ed" />
            </div>
        );
    }
}

Loader.propTypes = {
};

export default Loader;