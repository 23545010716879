import React from "react";
import PropTypes from "prop-types";
import { getSearchParam, socialInfoServices } from "../shared";
import ROUTES from "../constants/routes";

import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";

import Cookies from "js-cookie";
import Navbar from "../components/Navbar";
import { toast } from "react-toastify";

class AuthPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            email: "",
            social: {},
            editInfoMode: getSearchParam("edit") !== null
        };

        const cookie = Cookies.get("userinfo");
        if(cookie !== null && cookie !== undefined && cookie.length > 0){
            const jsonCookie = JSON.parse(cookie);
            if(jsonCookie.social !== null && jsonCookie.social !== undefined) this.state.social = jsonCookie.social;
            if(jsonCookie.email !== null) this.state.email = jsonCookie.email;
        }

        this.dest = getSearchParam("dest");
        if (this.dest == null || this.dest == "") {
            window.location.href = ROUTES.home;
            return;
        }

        let fieldsString = getSearchParam("fields");
        if(fieldsString == null){
            console.error("No fields param provided on auth page");
            fieldsString = "";
            this.socialFields = [];
        } else {
            fieldsString = decodeURIComponent(fieldsString);
            if(fieldsString.trim().length < 1){
                this.socialFields = [];
            }else{
                this.socialFields = fieldsString.split(",");
            }
        }
        
        firebase.auth().getRedirectResult().then(res => {
            if(res.user == null){
                return;
            }
            this.user = res.user;
            if(this.state.email === null){
                return;
            }
            firebase.firestore().collection("users").doc(res.user.uid).set({
                name: res.user.displayName,
                email: this.state.email,
                social: this.state.social
            }).then(() => {
                window.location.replace(decodeURIComponent(this.dest));
            }, console.error);
        }).catch(e => {
            console.error(e);
        });

        firebase.auth().onAuthStateChanged(user => {
            if(user === null) {
                this.user = null;
            } else {
                this.user = user;
            }
        });
    }

    signIn = (method) => {
        const auth = firebase.auth();

        const info = {
            social: this.state.social,
            email: this.state.email
        };

        const code = getSearchParam("code");
        if(code !== undefined && code !== ""){
            info.code = code;
        }

        Cookies.set("userinfo", JSON.stringify(info), { expires: 3650 });
        if(this.state.editInfoMode){
            window.location.replace(decodeURIComponent(this.dest));
            return;
        }

        auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
            if (method == "anonymous") {
                if (this.state.name.trim() == "") {
                    toast.error("Please enter a name.");
                    return;
                }

                auth.signInAnonymously().then((res) => {
                    res.user.updateProfile({
                        displayName: this.state.name
                    }).then(async () => {
                        await firebase.firestore().collection("users").doc(res.user.uid).set({
                            name: this.state.name,
                            email: this.state.email,
                            social: this.state.social
                        });
                        window.location.replace(decodeURIComponent(this.dest));
                    }, e => {
                        console.error(e);
                    });
                }, e => {
                    console.error(e);
                });
            } else if (method == "google") {
                const provider = new firebase.auth.GoogleAuthProvider();
                auth.signInWithPopup(provider).then(async (res) => {
                    await firebase.firestore().collection("users").doc(res.user.uid).set({
                        name: res.user.displayName,
                        email: res.user.email,
                        social: this.state.social
                    });
                    window.location.replace(decodeURIComponent(this.dest));
                }, (e) => {
                    console.error(e);
                    if(e.code === "auth/popup-blocked"){
                        auth.signInWithRedirect(provider).then(() => {
                            console.log("Redirecting...");
                        }, console.error);
                    }
                });
            }
        }, (e) => {
            // TODO: Display a message saying user should enable cookies
            console.error(e);
        });
    }

    render() {
        const buttonDisabled = !this.state.editInfoMode && (this.state.name === "");
        return (
            <div>
                <Navbar />
                <div className="mt-8 max-w-3xl mx-auto px-6">
                    <p className="leading-none text-4xl font-medium">Welcome!</p>
                    <p className="leading-none text-xl mt-3 font-light text-gray-600">
                        {
                            this.state.editInfoMode ? "Before we get started, make sure your info is up to date so others can contact you." : "Enter some basic information so others can contact you."
                        }
                    </p>
                    <div className="mt-6">
                        {
                            this.state.editInfoMode ? null : (
                                <div className="flex items-center">
                                    <img className="w-6 mr-4" src="https://image.flaticon.com/icons/svg/1077/1077114.svg" />
                                    <input className="rounded flex w-full px-4 py-3 bg-gray-200 border border-gray-200 focus:bg-white focus:border-gray-300" value={this.state.name} onChange={e => {
                                        this.setState({ name: e.target.value });
                                    }} placeholder="Name" />
                                </div>
                            )
                        }
                        <div className="flex items-center mt-4">
                            <img className="w-6 mr-4" src="https://image.flaticon.com/icons/svg/561/561127.svg" />
                            <input className="rounded flex w-full px-4 py-3 bg-gray-200 border border-gray-200 focus:bg-white focus:border-gray-300" value={this.state.email} onChange={e => {
                                this.setState({ email: e.target.value });
                            }} placeholder="Email" />
                        </div>
                        {
                            this.socialFields.map(field => {
                                const i = socialInfoServices[field];
                                return (
                                    <div className="flex items-center mt-4">
                                        <img className="w-6 mr-4" src={i.image} />
                                        <input className="rounded flex w-full px-4 py-3 bg-gray-200 border border-gray-200 focus:bg-white focus:border-gray-300" value={field in this.state.social ? this.state.social[field] : ""} onChange={e => {
                                            const newSocial = this.state.social;
                                            newSocial[field] = e.target.value;
                                            this.setState({ social: newSocial });
                                        }} placeholder={i.displayName} />
                                    </div>
                                );
                            })
                        }
                        <button className={`rounded mt-6 w-full px-3 py-3 text-white font-semibold text-xl ${buttonDisabled ? "bg-blue-200 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-400 shadow-xl"}`} onClick={() => {
                            this.signIn("anonymous");
                        }} disabled={buttonDisabled}>Continue</button>
                        {
                            this.state.editInfoMode ? null : (
                                <React.Fragment>
                                    <p className="mt-6 text-gray-500 text-center tracking-widest uppercase font-bold">Or...</p>
                                    <div className="mt-6 flex justify-center">
                                        <button class="shadow-lg flex bg-gray-100 hover:bg-gray-200 focus:bg-gray-200 border border-gray-200 google-button items-center px-6 py-4" onClick={() => { this.signIn("google"); }}>
                                            <span class="google-button__icon block w-5 h-5 rounded">
                                                <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z" id="Shape" fill="#EA4335" /><path d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z" id="Shape" fill="#FBBC05" />
                                                    <path d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z" id="Shape" fill="#4285F4" />
                                                    <path d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z" fill="#34A853" />
                                                </svg>
                                            </span>
                                            <span class="ml-3 font-bold uppercase text-gray-600 tracking-wide text-sm">Sign in with Google</span>
                                        </button>
                                    </div>
                                </React.Fragment>
                            )
                        }
                    </div>
                </div>
            </div >
        );
    }
}

AuthPage.propTypes = {

};

export default AuthPage;