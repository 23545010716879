import React from "react";
import PropTypes from "prop-types";

import video, { Participant } from "twilio-video";
import Loader from "./Loader";
import VideoParticipant from "./VideoParticipant";
// import { MicIcon } from '@material-ui/icons'
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import { toast } from "react-toastify";
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import NoGroupAssigned from "./NoGroupAssigned";
import { Beforeunload } from 'react-beforeunload';

class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            room: null,
            audio: true,
            video: true,
            permissionDenied: false,
            timeNotification: false
        };
        this.group = Object.keys(props.data.groups).find(key => props.data.groups[key].includes(props.user.uid));
        console.log(props.data.groups);
        this.joining = false;
        this.joinCall(0);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.joining) {
            this.joinCall(0);
        }
    }

    componentWillUnmount() {
        const { room } = this.state;
        if (room !== null) {
            room.localParticipant.tracks.forEach((track) => {
                track.track.stop();
            });
            const tracks = Array.from(room.localParticipant.tracks.values()).map(publication => publication.track);
            room.localParticipant.unpublishTracks(tracks);
            room.disconnect();
        }
    }

    joinCall = (tries) => {
        const token = this.props.data.participants[this.props.user.uid].twilioToken;
        if (token == "") return;
        console.log("Joining with token " + token);
        this.joining = true;
        if(this.group == undefined){
            console.log("No group, not joining call");
            return;
        }
        const roomName = `${this.props.match.params.roomCode}-${this.group}`;
        video.connect(token, { name: roomName }).then(room => {
            console.log(room);
            this.setState({ room: room });
            room.on("participantConnected", () => this.forceUpdate());
            room.on("participantDisconnected", () => {
                this.forceUpdate();
                console.log("Participant disconnect");
            });
            room.on("trackUnpublished", () => this.forceUpdate());
        }, e => {
            if (e.message == "Permission denied") {
                this.setState({ permissionDenied: true });
            }
            toast.error("Error: " + e.message);
            console.error(e);
            setTimeout(() => {
                console.log("Attempting to join call, try #" + (tries + 1) + ". Trying again in " + Math.pow(1.5, tries) + "secs");
                this.joinCall(tries + 1);
            }, Math.pow(1.5, tries) * 1000);
        });
    }

    handleAudioClick = () => {
        const newAudioState = !this.state.audio;
        const tracks = Array.from(this.state.room.localParticipant.audioTracks.values());
        if (tracks.length > 0) {
            const audioTrack = tracks[0].track;
            audioTrack.enable(newAudioState);
            this.setState({ audio: newAudioState });
        }
    }

    handleVideoClick = () => {
        const newVideoState = !this.state.video;
        const tracks = Array.from(this.state.room.localParticipant.videoTracks.values());
        if (tracks.length > 0) {
            const videoTrack = tracks[0].track;
            videoTrack.enable(newVideoState);
            this.setState({ video: newVideoState });
        }
    }

    render() {
        const { data } = this.props;
        const { room } = this.state;

        const now = new Date()

        if (!this.state.timeNotification && ((data.nextRound.toDate() - now) / 1000) < 30) {
            toast("Rooms ending soon!")
            this.setState({ timeNotification: true });
        }


        if (this.state.error != null) {
            return (
                <div>
                    <p className="text-2xl font-medium text-gray-800">Error: {this.state.error}</p>
                </div>
            );
        }

        if (this.state.permissionDenied) {
            return (
                <div className="px-6 max-w-3xl mx-auto">
                    <p className="text-3xl mt-6 font-semibold">Permission denied</p>
                    <p className="text-gray-600 text-lg">Camera and microphone access is required to use this app. Please enable and refresh.</p>
                    <button className="mt-4 px-4 py-3 text-white rounded shadow bg-blue-400 hover:bg-blue-300" onClick={() => {
                        window.location.reload();
                    }}>I've enabled camera and mic</button>
                </div>
            );
        }

        if (this.group === undefined) {
            return (
                <NoGroupAssigned />
            );
        }

        if (room == null) {
            return <Loader />;
        }
        return (
            <>
                <p className="text-center text-gray-500 my-1">Round {data.round}</p>

                <div className="px-6 md:flex pt-4">
                    <Beforeunload onBeforeunload={(e) => {
                        room.disconnect();
                    }} />
                    <div className="pb-10 pl-10 left-0 bottom-0 fixed z-40">
                        <div className="bg-blue-500 p-5 rounded-full shadow-xl text-white">
                            <button className="mr-6" onClick={this.handleAudioClick}>
                                {this.state.audio ? <MicIcon style={{ fontSize: "30px" }} /> : <MicOffIcon style={{ fontSize: "30px" }} />}
                            </button>
                            <button onClick={this.handleVideoClick}>
                                {this.state.video ? <VideocamIcon style={{ fontSize: "30px" }} /> : <VideocamOffIcon style={{ fontSize: "30px" }} />}
                            </button>
                        </div>
                    </div>

                    <div className="flex-1 z-0">
                        <div className="flex flex-wrap items-center justify-center">
                            <VideoParticipant participant={room.localParticipant} data={this.props.data} isLocal />
                            {
                                Array.from(room.participants.values()).map(participant => {
                                    return (
                                        <VideoParticipant participant={participant} data={this.props.data} />
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="pt-5 md:pt-0 md:pl-5 w-full md:max-w-xs">
                        <p className="text-gray-500 tracking-widest uppercase font-bold mb-3">Prompts</p>
                        {data.prompts.map((prompt) => {
                            return (
                                <div className="border-t border-gray-200 mt-3 pt-3">
                                    <p className="text-gray-700 font-light text-sm">{prompt}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
        );
    }
}

Video.propTypes = {
    data: PropTypes.object.isRequired
};

export default Video;