import React from "react";
import PropTypes from "prop-types";
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import MicOffIcon from '@material-ui/icons/MicOff';

class VideoParticipant extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            video: true,
            audio: true
        };

        this.videoRef = React.createRef();
        this.audioRef = React.createRef();

        const { participant } = props;
        if (participant.videoTracks.size > 0) {
            this.videoPublication = Array.from(participant.videoTracks.values())[0];
            if (!this.props.isLocal) {
                this.videoPublication.on("subscribed", () => {
                    if (this.videoPublication.track != null) {
                        this.startVideoTracks();
                    }
                });
            }
        }
        if (participant.audioTracks.size > 0) {
            this.audioPublication = Array.from(participant.audioTracks.values())[0];
            if (!this.props.isLocal) {
                this.audioPublication.on("subscribed", () => {
                    console.log("Subscribed")
                    if (this.audioPublication.track != null) {
                        this.startAudioTracks();
                    }
                });
            }
        }
    }

    startVideoTracks() {
        this.videoPublication.track.attach(this.videoRef.current);
        this.videoPublication.track.on("enabled", () => {
            this.setState({ video: this.videoPublication.track.isEnabled });
        });
        this.videoPublication.track.on("disabled", () => {
            this.setState({ video: this.videoPublication.track.isEnabled });
        });
    }
    startAudioTracks() {
        this.audioPublication.track.attach(this.audioRef.current);
        this.audioPublication.track.on("enabled", () => {
            this.setState({ audio: this.audioPublication.track.isEnabled });
            console.log("Enabled");
        });
        this.audioPublication.track.on("disabled", () => {
            this.setState({ audio: this.audioPublication.track.isEnabled });
            console.log("Disabled");
        });
    }

    componentDidMount() {
        if (this.props.isLocal) {
            this.startAudioTracks();
            this.startVideoTracks();
        }
    }

    render() {
        const { participant, data } = this.props;
        const participantData = data.participants[participant.identity];
        return (
            <div className="p-px" style={{ position: "relative", width: "50%" }}>
                <div className="flex items-center justify-center h-full">
                    <div className="absolute flex pl-3 top-0 pt-3 z-40">
                        <p className="bg-white font-medium px-2 py-1 text-black leading-none rounded shadow">{participantData == undefined ? "LinkU Participant" : participantData.displayName}</p>
                    </div>
                    <div>
                        <div className="absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center z-0 bg-black">
                            {/* for equal */}
                        </div>
                        <video className="call-video z-20 w-auto" style={{ height: "40vh" }} ref={this.state.video ? this.videoRef : null} autoPlay={true} />
                        <audio ref={this.audioRef} autoPlay={true} />
                        {
                            this.state.video ? null : (
                                <div className="absolute top-0 bottom-0 right-0 left-0 flex items-center justify-center bg-black rounded-lg">
                                    <p className="font-medium text-white">
                                        <VideocamOffIcon />
                                        <span className="ml-3">Video turned off</span>
                                    </p>
                                </div>
                            )
                        }

                        {
                            this.state.audio ? null : (
                                <div className="absolute bottom-0 pb-4 left-0 right-0 flex justify-center">
                                    <p className="text-white font-medium rounded bg-red-600 px-2 py-1 text-sm shadow flex items-center">
                                        <MicOffIcon style={{ fontSize: "18px" }} />
                                        <span className="ml-1">Mic muted</span>
                                    </p>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

VideoParticipant.propTypes = {
    participant: PropTypes.object.isRequired,
    isLocal: PropTypes.bool.isRequired
};

export default VideoParticipant;