import React from "react";
import PropTypes from "prop-types";

class BrowserNotSupported extends React.Component {
    
    render(){
        return (
            <div className="px-6 max-w-3xl mx-auto">
                    <p className="text-3xl mt-6 font-semibold">Your browser isn't supported.</p>
                    <p className="text-gray-600 text-lg">Your browser is outdated or does not support the WebRTC protocol. To use LinkU, please use a different browser that's up-to-date.</p>
                </div>
        );
    }
}
export default BrowserNotSupported;