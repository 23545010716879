import React, { Component } from 'react';

class HowItWorks extends Component {
    render() {
        return (
            <div className="">
                <div className="flex items-center">
                    <div className="">
                        <img className="w-12" src="https://image.flaticon.com/icons/svg/1828/1828395.svg" />
                    </div>
                    <div className="flex-1 ml-10">
                        <p className="text-xl leading-tight font-medium text-black">Create or join a session</p>
                        <p className="text-lg text-gray-500 leading-tight mt-3 font-light">
                            Share the link and code with the people you want to get to know!
                        </p>
                    </div>
                </div>

                <div className="flex items-center mt-16">
                    <div className="">
                        <img className="w-12" src="https://image.flaticon.com/icons/svg/2891/2891192.svg" />
                    </div>
                    <div className="flex-1 ml-10">
                        <p className="text-xl leading-tight font-medium text-black">Link Up</p>
                        <p className="text-lg text-gray-500 leading-tight mt-3 font-light">
                            Use your link or code to join a session with multiple randomized groups that each pose an array of conversation starters.
                        </p>
                    </div>
                </div>

                <div className="flex items-center mt-16">
                    <div className="">
                        <img className="w-12" src="https://image.flaticon.com/icons/svg/3010/3010912.svg" />
                    </div>
                    <div className="flex-1 ml-10">
                        <p className="text-xl leading-tight font-medium text-black">Get Matched</p>
                        <p className="text-lg text-gray-500 leading-tight mt-3 font-light">
                            Get in touch with the people you mutually connected with over social media!
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default HowItWorks;