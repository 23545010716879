import React, { Component } from 'react';
import ROUTES from '../constants/routes';
import "firebase/auth"
import firebase from "firebase"
import Cookies from "js-cookie";
import { toast } from 'react-toastify';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: false
        }

    }

    componentDidMount() {
        this.getCurrentUser()
    }

    getCurrentUser = async () => {
        const info = Cookies.get("userinfo");
        const user = typeof info !== "undefined";

        this.setState({ user: user });
    }

    signOut = () => {
        const auth = firebase.auth()
        auth.signOut().then(() => {
            Cookies.remove('userinfo')
            toast.success("Successfully signed out!")
            this.setState({ user: false });
        }).catch(() => {
            toast.error("Failed to sign out.")
        })
    }

    render() {
        return (
            <div className="shadow">
                <div className="max-w-3xl px-6 mx-auto">
                    <div className="flex justify-between items-center">
                        <div>
                            <a href={ROUTES.home} className="leading-none text-xl font-semibold text-blue-500">LinkU</a>
                        </div>
                        <div className="flex -mx-2 md:-mx-3 items-center">
                            <a href={ROUTES.contact} className="text-sm md:text-base mx-2 md:mx-3 leading-none text-gray-500 hover:text-gray-700 font-light">Contact</a>

                            {!this.state.user ? "" : (
                                <button href={ROUTES.contact} className={"py-6 text-sm md:text-base outline-none mx-2 md:mx-3 leading-none text-gray-500 hover:text-gray-700 font-light"} onClick={this.signOut}>Sign out</button>
                            )}
                            
                            {/* ONLY SHOWS JOIN ROOM IF USER IS NOT IN A ROOM ALREADY */}
                            {window.location.pathname.split("/")[1] === ROUTES.room.split("/")[1] ? null : (
                                <a href={ROUTES.join} className="text-sm md:text-base mx-2 md:mx-3 bg-blue-600 hover:bg-blue-500 font-light px-5 py-6 text-white shadow leading-none">Join room</a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Navbar;