import React from "react";
import PropTypes from "prop-types";

import firebase from "firebase";
import "firebase/firestore";
import "firebase/auth";

import moment from "moment";
import Cookies from "js-cookie";

import Loader from "../components/Loader";
import ROUTES from "../constants/routes";
import Lobby from "../components/Lobby";
import Video from "../components/Video";
import Break from "../components/Break";
import Navbar from "../components/Navbar";
import Scheduled from "../components/Scheduled";
import Finished from "../components/Finished";
import { Beforeunload } from "react-beforeunload";
import { IsWebRTCSupported } from "../shared";
import BrowserNotSupported from "../components/BrowserNotSupported";

class RoomPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
        };
        this.roomCode = this.props.match.params.roomCode.toLowerCase().trim();
        if (typeof Cookies.getJSON(this.props.roomCode) === "undefined") {
            Cookies.set(this.props.roomCode, []);
        }
        this.firestore = firebase.firestore();
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.user = user;
                const cookie = Cookies.get("userinfo");
                if(cookie == null){
                    this.redirectToAuth(true);
                    return;
                }
                this.info = JSON.parse(cookie);
                if(this.info.code !== this.roomCode){
                    this.redirectToAuth(true);
                    return;
                }
                this.startListener();
                this.joinRoom();
            } else {
                this.redirectToAuth(false);
            }
        });
        setInterval(() => {
            this.forceUpdate(); // Force timer update every second
        }, 1000);
    }

    onUnload = (e) => {
        // e.preventDefault();
        this.firestore.collection("rooms").doc(this.roomCode).update({
            [`participants.${this.user.uid}.connected`]: false
        }).then(() => {}, (e) => {
            console.error(e);
        });
    }

    redirectToAuth = (infoEditMode) => {
        this.firestore.collection("rooms").doc(this.roomCode).get().then(doc => {
            if(!doc.exists){
                alert("A room with that code does not exist.");
                window.location.href = ROUTES.join;
                return;
            }
            const data = doc.data();
            const fields = data.socialInfo.length < 1 ? "" : data.socialInfo.reduce((p, c) => p + "," + c);
            window.location.replace(`${ROUTES.auth}?dest=${encodeURIComponent(window.location.href)}&code=${this.roomCode}${infoEditMode ? "&edit" : ""}&fields=${encodeURIComponent(fields)}`);
        });
    }

    joinRoom = () => {
        

        this.firestore.collection("rooms").doc(this.roomCode).update({
            ["participants." + this.user.uid]: {
                twilioToken: "",
                displayName: this.user.displayName,
                social: this.info.social,
                email: this.user.email,
                connected: true
            }
        }).then(() => {
            // ...
        }, (e) => {
            console.error(e);
        });
    }

    startListener = () => {
        this.firestore.collection("rooms").doc(this.roomCode).onSnapshot(doc => {
            if (!doc.exists) {
                alert("A room with that code does not exist.");
                window.location.href = ROUTES.join;
            }
            this.setState({ data: doc.data() });
        }, (e) => {
            console.error(e);
        });
    }

    render() {
        
        if(!IsWebRTCSupported()) {
            return (
                <>
                    <Navbar />
                    <BrowserNotSupported />
                </>
            );
        }

        const { data } = this.state;

        if (data === null) {
            return <Loader />;
        }
        

        let content;
        const nextRound = moment(data.nextRound.toDate()).toNow(true);
        const startTime = moment(data.startTime.toDate()).toNow(true);
        let nextRoundString = null;
        switch (data.state) {
            case "scheduled":
                content = (
                    <Scheduled data={data} user={this.user} {...this.props} />
                );
                nextRoundString = (
                    <span>Event starting in <span className="text-gray-600">{startTime}</span></span>
                );
                break;
            case "idle":
                content = (
                    <Lobby data={data} user={this.user} {...this.props} />
                );
                nextRoundString = (
                    <span>Starting in <span className="text-gray-600">{nextRound}</span></span>
                );
                break;
            case "video":
                content = (
                    <Video data={data} user={this.user} {...this.props} />
                );
                nextRoundString = (
                    <span>New groups in <span className="text-gray-600">{nextRound}</span></span>
                );
                break;
            case "break":
                content = (
                    <Break data={data} user={this.user} roomCode={this.roomCode} {...this.props} />
                );
                nextRoundString = (
                    <span>Next group in <span className="text-gray-800">{nextRound}</span></span>
                );
                break;
            case "finished":
                content = (
                    <Finished roomCode={this.roomCode} data={data} user={this.user} {...this.props} />
                );
                nextRoundString = null;
                break;
            default:
                throw new Error("Room state is not a valid option!");
        }

        return (
            <div>
                <Navbar />
                <Beforeunload onBeforeunload={this.onUnload} />
                {this.state.data.state === "finished" ? "" : (
                    <div className="max-w-3xl mx-auto px-6 mt-4">
                        <div className="flex justify-between">
                            <p className="text-gray-500 font-light">Room code: <span className="text-gray-600">{this.roomCode}</span></p>
                            <p className="text-gray-500 font-light">{nextRoundString}</p>
                        </div>
                    </div>
                )}

                {content}
            </div>
        );
    }
}

RoomPage.propTypes = {

};

export default RoomPage;