import React from "react";
import PropTypes from "prop-types";
import {
    Tooltip,
} from 'react-tippy';
import 'react-tippy/dist/tippy.css'
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Cookies from "js-cookie";
import Contact from "./Contact";

class Finished extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { data } = this.props;
        const myPrevGroups = data.previousGroups.map(i => {
            const keys = Object.keys(i);
            return i[keys.find(k => i[k].includes(this.props.user.uid))];
        });
        console.log(myPrevGroups)
        return (
            <div>
                <div className="py-24 bg-finished flex justify-center items-center">
                    <div className="text-center ">
                        <div className="text-white">
                            <DoneAllIcon style={{ fontSize: "80px" }} />
                        </div>

                        <p className="text-5xl text-white leading-none mt-6 font-medium">Thank you!</p>
                        <p className="text-2xl mt-3 text-gray-200 font-light">Thank you for attending {data.displayName}!</p>
                    </div>
                </div>
                <div className="py-20 max-w-3xl mx-auto px-6">
                    <div>
                        <p className="font-medium text-3xl">Your groups</p>
                        <div className="-my-8 pt-5">
                            {myPrevGroups.map((group, i) => {
                                if(group == undefined) return null;
                                return (
                                    <div className="my-8">
                                        <p className="uppercase font-semibold text-gray-500">Group {i + 1}</p>

                                        <div className="pt-1">
                                            {
                                                group.map((uid) => {
                                                    const participant = data.participants[uid];
                                                    return (
                                                        <Contact finished uid={uid} roomCode={this.props.roomCode} contact={participant} />
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Finished.propTypes = {

};

export default Finished;