import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import HowItWorks from '../components/HowItWorks';
import ROUTES from '../constants/routes';
import Footer from '../components/Footer';

class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const primaryHex = "#002fff";
        const secondaryHex = "#0014b0";
        const tertiaryHex = "#596cff";

        return (
            <div>
                <div className="flex bg-home  flex-col py-12 xl:p-0 xl:h-screen">
                    {/* <Navbar /> */}

                    <div className="flex-1 flex items-center">
                        <div className="max-w-6xl mx-auto px-6">
                            <div>
                                <p className="leading-none font-bold text-4xl tracking-wider uppercase" style={{ color: secondaryHex }}><i class="fas fa-link text-3xl"></i> LinkU </p>
                                <p className="leading-none text-5xl md:text-6xl mt-12 max-w-4xl" style={{ color: primaryHex }}>Uniting communities through lasting friendships.</p>
                                {/* <p className="leading-tight mt-5 tracking text-gray-300 font-light text-4xl">Join us on our mission todya</p> */}

                                <div className="mt-16">
                                    <div className="md:flex justify-start items-stretch">


                                        <a href={ROUTES.join} style={{ backgroundColor: primaryHex }} className="mb-4 md:mb-0 md:mr-6 px-6 py-4 hover:shadow-xl flex items-center">
                                            <div className="flex-1">
                                                <p className="font-medium text-white text-xl">Join Room</p>
                                                {/* <p className="font-light text-white">Contact us for any inquiries.</p> */}
                                            </div>
                                            <div>
                                                <i class="fas fa-chevron-right ml-4 text-white"></i>
                                            </div>
                                        </a>

                                        <a href="mailto:airfan1@hwemail.com" target="_blank" ref="noopener noreferrer" style={{ color: primaryHex }} className="px-6 py-4 bg-white border border-gray-200 hover:border-gray-400 flex items-center">
                                            <div className="flex-1" >
                                                <p className="font-medium"  >Currently available at select schools.</p>
                                                <p className="font-light">Contact us for any inquiries.</p>
                                            </div>
                                            <div>
                                                <i class="fas fa-chevron-right ml-4"></i>
                                            </div>
                                        </a>


                                    </div>
                                </div>

                                <div className="mt-20">
                                    <div className="md:flex md:-mx-8 -mt-8 md:mt-0 items-start justify-center">
                                        <div className="flex-1 md:mx-8 mt-8 md:mt-0">
                                            <img className="h-20 ww-icon" src="https://image.flaticon.com/icons/svg/1828/1828395.svg" />

                                            <div className="mt-8">
                                                <p className="font-medium text-black text-xl">Join a Session</p>
                                                <p className="font- text-gray-700 text-md mt-3">Share the link and code with the people you want to get to know!</p>
                                            </div>
                                        </div>

                                        <div className="flex-1 md:mx-8 mt-8 md:mt-0">
                                            <img className="h-20 ww-icon" src="https://image.flaticon.com/icons/svg/2891/2891192.svg" />

                                            <div className="mt-8">
                                                <p className="font-medium text-black text-xl">Link Up</p>
                                                <p className="font- text-gray-700 text-md mt-3">Use your link or code to join a session with multiple randomized groups that each pose an array of conversation starters.</p>
                                            </div>
                                        </div>

                                        <div className="flex-1 md:mx-8 mt-8 md:mt-0">
                                            <img className="h-20 ww-icon" src="https://image.flaticon.com/icons/svg/3010/3010912.svg" />

                                            <div className="mt-8">
                                                <p className="font-medium text-black text-xl">Get Matched</p>
                                                <p className="font- text-gray-700 text-md mt-3">Get in touch with the people you mutually connected with over social media!</p>
                                            </div>
                                        </div>


                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


                <Footer />
            </div>
        );
    }
}

export default HomePage;