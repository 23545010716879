import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { ToastContainer } from "react-toastify";

import firebase from "firebase";
import moment from "moment";
import ROUTES from "./constants/routes"

import JoinPage from "./pages/JoinPage";
import RoomPage from "./pages/RoomPage";
import "react-toastify/dist/ReactToastify.css";
import "./stylesheets/index.css";
import "react-phone-number-input/style.css";
import { firebaseConfig } from "./shared";
import AuthPage from "./pages/AuthPage";
import HomePage from "./pages/HomePage";
import AdminPage from "./pages/AdminPage";
import Page404 from "./components/PageNotFound";

class App extends Component {
    constructor(props) {
        super(props);

        if (firebase.apps.length === 0) firebase.initializeApp(firebaseConfig);

        moment.updateLocale("en", {
            relativeTime : {
                future: "in %s",
                past:   "%s ago",
                s  : "%d seconds",
                ss : "%d seconds",
                m:  "a minute",
                mm: "%d minutes",
                h:  "an hour",
                hh: "%d hours",
                d:  "a day",
                dd: "%d days",
                w:  "a week",
                ww: "%d weeks",
                M:  "a month",
                MM: "%d months",
                y:  "a year",
                yy: "%d years"
            }
        });
    }

    render() {
        return (
            <Router>
                <ToastContainer draggable={false} closeOnClick={false} />

                <Switch>
                    <Route path={ROUTES.home} exact component={HomePage} />
                    <Route path={ROUTES.join} exact component={JoinPage} />
                    <Route path={ROUTES.room + "/:roomCode"} component={RoomPage} />
                    <Route path={ROUTES.auth} component={AuthPage} />
                    <Route path={ROUTES.admin} component={AdminPage} />
                    <Route path="/" component={Page404} />
                </Switch>
            </Router>
        );
    }
}

export default App;
