import SMSIcon from "./resources/sms.svg";

export const firebaseConfig = {
    apiKey: "AIzaSyCkuKSqCD0DaUOSPrY-91BxBhFDn86ZrvI",
    authDomain: "college-connect-7dc79.firebaseapp.com",
    databaseURL: "https://college-connect-7dc79.firebaseio.com",
    projectId: "college-connect-7dc79",
    storageBucket: "college-connect-7dc79.appspot.com",
    messagingSenderId: "907789601394",
    appId: "1:907789601394:web:5008cfefb9cbe7b34ad0eb",
    measurementId: "G-HLTN42XNNM"
};

/**
 * Checks if a room code is valid or not.
 * @param {string} code The room code to check.
 * @returns `true` if the room code is valid, `false` otherwise.
 */
export function validateRoomCode(code){
    return code.trim().length > 0;
}

/**
 * 
 * @param {string} paramName The URL parameter to search for. 
 * @param {string} [queryString=window.location] The search part of the URL to search through.
 * @returns The value of the search parameter.
 */
export function getSearchParam(paramName, queryString = null){
    if(queryString === null) queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(paramName);
}

export function IsWebRTCSupported() {
    // https://blog.ivrpowers.com/post/coding/browser-webrtc/
    if (window.navigator.userAgent.indexOf("Edge") > -1) {
        return false;
    }

    return navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || window.RTCPeerConnection;
}

export const socialInfoServices = {
    phone: {
        displayName: "Phone #",
        abbreviatedName: "SMS",
        image: SMSIcon,
        toClickableLink: (value) => "sms:" + value
    },
    instagram: {
        displayName: "Instagram",
        abbreviatedName: "Insta",
        image: "https://image.flaticon.com/icons/svg/1384/1384063.svg",
        toClickableLink: (value) => `https://instagram.com/${value.replace("@", "")}`
    },
    snapchat: {
        displayName: "Snapchat",
        abbreviatedName: "Snap",
        image: "https://image.flaticon.com/icons/svg/2111/2111616.svg",
        toClickableLink: (value) => `https://snapchat.com/add/${value}`
    }
}