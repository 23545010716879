import React, { Component } from 'react';
import { validateRoomCode } from '../shared';
import ROUTES from '../constants/routes';

class JoinPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            code: ""
        };
    }

    render() {
        const roomCodeValid = validateRoomCode(this.state.code);

        return (
            <div className="h-screen flex items-center justify-center bg-blue-500">
                <div className="max-w-lg px-6">
                    <p className="leading-none uppercase tracking-widest text-lg text-blue-200 font-light mb-5">Join a room</p>
                    <p className="leading-none text-5xl text-white font-medium mb-2">Enter your room code.</p>
                    <p className="leading-tight text-lg font-light mb-8 text-gray-300">Your room code should be a 4-6 character code given to you by the organizers of your event.</p>
                    <input className="flex w-full px-4 py-3 bg-white focus:bg-gray-100 mb-4 text-xl rounded" placeholder="Code" value={this.state.code} onChange={(e) => {
                        this.setState({ code: e.target.value.toLowerCase().trim() });
                    }} />

                    <button className={"w-full px-4 py-3 text-white text-xl rounded" + (roomCodeValid ? " bg-gray-900 hover:bg-black shadow-xl" : " cursor-not-allowed bg-gray-400")} disabled={!roomCodeValid} onClick={() => {
                        if (!roomCodeValid) return;
                        window.location.href = `${ROUTES.room}/${this.state.code}`;
                    }}>{roomCodeValid ? "Join" : "Enter a room code"}</button>
                </div>
            </div>
        )
    }
}

export default JoinPage;