import React from "react";
import PropTypes from "prop-types";

import { HashLoader } from "react-spinners";

class NoGroupAssigned extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    
    render(){
        return (
            <div className="max-w-3xl mx-auto px-6 mt-20">
                    <div className="max-w-md mx-auto mt-12 text-center">
                        <div>
                            <div className="flex justify-center">
                                <HashLoader size={60} color={"#63b3ed"} />
                            </div>
                            <p className="mt-12 text-5xl text-black leading-none font-medium">Welcome!</p>
                            <p className="text-4xl text-blue-500 leading-none font-medium mt-2">Join next round.</p>
                            <p className="text-xl text-gray-500 font-light mt-5">The event has already started, but we're glad you're here. Wait until the next round to start chatting!</p>
                        </div>
                    </div>
                </div>
        );
    }
}

export default NoGroupAssigned;