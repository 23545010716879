import React, { Component } from 'react';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import Cookies from 'js-cookie';
import { Tooltip } from 'react-tippy';

import {socialInfoServices} from "../shared";

class Contact extends Component {
    constructor(props) {
        super(props)

        this.state = {
            added: typeof Cookies.getJSON(this.props.roomCode) === "undefined" ? false : Cookies.getJSON(this.props.roomCode).includes(this.props.uid[0])
        }
    }


    handleClick = () => {

        const key = this.props.roomCode
        let contactsAdded = typeof Cookies.getJSON(key) === "undefined" ? [] : Cookies.getJSON(key)
        const addedNewState = !this.state.added
        const uid = this.props.uid[0]

        this.setState({ added: addedNewState });

        if (addedNewState === true) {
            // push to cookies
            if (!contactsAdded.includes(uid)) {
                contactsAdded.push(uid)
                Cookies.set(key, contactsAdded)
            }
        } else {
            var index = contactsAdded.indexOf(uid);
            if (index !== -1) {
                contactsAdded.splice(index, 1);
                Cookies.set(key, contactsAdded)
            }
        }
    }

    render() {
        const contact = this.props.contact

        return (
            <div
                className={
                    "mt-2 items-stretch flex rounded " + 
                    (this.props.finished ? "" : " up-on-hover hover:border-gray-400 cursor-pointer") + 
                    (this.state.added && this.props.finished && !this.props.hideHighlight ? " border-blue-500 border-2" : " border border-gray-200") +
                    (contact.connected || this.props.finished ? "" : "bg-gray-400")
                }
                onClick={(e) => { if (this.props.finished !== true) this.handleClick() }}
            >
                {!this.props.finished ? (
                    <Tooltip arrow postion="bottom" theme="light" title="Link up!">
                        <div className={"transition-quick text-xs font-medium px-2 flex items-center rounded-l h-full " + (this.state.added ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-500")}>
                            {this.state.added ? <CheckIcon style={{ fontSize: "20px" }} /> : <AddIcon style={{ fontSize: "20px" }} />}
                        </div>
                    </Tooltip>
                ) : null}
                <div className={"flex items-center justify-between flex-1 py-3 px-4"}>
                    <p className="h-full flex items-center text-gray-600 rounded-r leading-none">
                        {contact.displayName}
                        {
                            contact.connected || this.props.finished ? null : (
                                <PowerOffIcon style={{color: "#FF0000", marginLeft: "5px"}} />
                            )
                        }
                    </p>
                    {this.props.finished ? (
                        <div className="-mx-2 flex items-center">
                            <a href={"mailto:" + contact.email} target="_blank" rel="noopener noreferrer">
                                <p className="text-sm mx-2 font-light text-gray-500">{"Email"}: <b>{contact.email}</b></p>
                            </a>
                            {
                                Object.keys(contact.social).map(service => {
                                    const i = contact.social[service];
                                    if(i.trim().length < 1) return null;
                                    const serviceInfo = socialInfoServices[service];
                                    return (
                                        <a href={serviceInfo.toClickableLink(i)} target="_blank" rel="noopener noreferrer">
                                            <p className="text-sm mx-2 font-light text-gray-500">{serviceInfo.abbreviatedName}: <b>{i}</b></p>
                                        </a>
                                    );
                                })
                            }
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default Contact;