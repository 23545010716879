import React from "react";
import PropTypes from "prop-types";

import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";

import {toast} from "react-toastify";

import DateTimePicker from "react-datetime-picker";
import ROUTES from "../constants/routes";
import Navbar from "../components/Navbar";
import { socialInfoServices } from "../shared";

class AdminPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            displayName: "LinkU Event",
            startTime: new Date(),
            groupSize: 4,
            totalRounds: 5,
            lobbyTime: 60,
            roundTime: 5 * 60,
            breakTime: 15,
            social: [],
            loading: false
        };
        this.db = firebase.firestore();
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.user = user;
                if (this.user.isAnonymous) {
                    alert("To access the admin page, please sign out, then sign in with Google.");
                    window.location.replace(ROUTES.home);
                }
            } else {
                window.location.replace(ROUTES.auth + "?dest=" + encodeURIComponent(window.location.href));
            }
        });
    }

    clearFinishedEvents = () => {
        if(!window.confirm("Really delete all finished events?")) return;
        this.db.collection("rooms").where("state", "==", "finished").get().then(snap => {
            const promises = snap.docs.map(doc => {
                return new Promise((resolve, reject) => {
                    doc.ref.delete().then(() => {
                        resolve();
                    }, (e) => {
                        toast.error(e.code);
                        console.error(e);
                    })
                });
            });
            Promise.all(promises).then(() => {
                toast(`${snap.size} finished events deleted!`);
            });
        })
    }

    createEvent = () => {
        if(this.state.id.trim().length <= 0){
            toast.error("Enter a room code");
            return;
        }
        this.db.collection("rooms").doc(this.state.id.trim()).set({
            displayName: this.state.displayName,
            startTime: this.state.startTime,
            groupSize: this.state.groupSize,
            totalRounds: this.state.totalRounds,
            lobbyTime: this.state.lobbyTime,
            roundTime: this.state.roundTime,
            breakTime: this.state.breakTime,
            nextRound: this.state.startTime,
            socialInfo: this.state.social,
            round: 0,
            state: "scheduled",
            groups: {},
            previousGroups: [],
            participants: {},
            prompts: [],
            previousPrompts: [],
            reminderNumbers: []
        }).then(() => {
            this.setState({loading: false});

            const successMsg = (
                <div className="p-2">
                    <p className="font-bold tracking-wide">Success! Shareable link:</p>
                    <p className="font-light tracking-wide">{"linku.live/room/"}<span className="font-semibold">{this.state.id.trim()}</span></p>
                </div>
            )

            toast.success(successMsg, {
                autoClose: 10000
            })
        }, (e) => {
            this.setState({loading: false});
            console.error(e);
            if(e.code == "permission-denied"){
                toast.error("Hacker Alert! You are not an authorized admin. This incident has been reported.");
            }else{
                toast.error("Something went wrong.");
            }
        })
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="py-12 bg-blue-500">
                    <div className="px-6 mx-auto max-w-3xl">
                        <p className="text-blue-300 uppercase tracking-widest font-bold mb-2 text-xl">Admin Portal</p>
                        <p className="text-white font-medium leading-none text-4xl">Create Event</p>
                    </div>
                </div>
                <div className="px-6 mx-auto max-w-3xl mb-20 mt-8">

                    <p><b>Room code:</b></p>
                    <input className="w-full mb-4 px-3 py-2 rounded border-gray-200 border focus:border-gray-500" value={this.state.id} type="text" onChange={(e) => {
                        this.setState({ id: e.target.value.toLowerCase() });
                    }} />
                    <p><b>Event name:</b></p>
                    <input className="w-full mb-4 px-3 py-2 rounded border-gray-200 border focus:border-gray-500" value={this.state.displayName} type="text" onChange={(e) => {
                        this.setState({ displayName: e.target.value });
                    }} />
                    <p><b>Start time:</b></p>
                    <DateTimePicker value={this.state.startTime} minDate={new Date()} onChange={(date) => {
                        this.setState({ startTime: date });
                    }} />
                    <p className="mt-4"><b>Group size:</b></p>
                    <input className="w-full mb-4 px-3 py-2 rounded border-gray-200 border focus:border-gray-500" value={this.state.groupSize} type="number" onChange={(e) => {
                        this.setState({ groupSize: Math.min(e.target.value) });
                    }} />
                    <p><b># of rounds:</b></p>
                    <input className="w-full mb-4 px-3 py-2 rounded border-gray-200 border focus:border-gray-500" value={this.state.totalRounds} type="number" onChange={(e) => {
                        this.setState({ totalRounds: Math.min(e.target.value) });
                    }} />
                    <p><b>Lobby waiting time (secs):</b></p>
                    <input className="w-full mb-4 px-3 py-2 rounded border-gray-200 border focus:border-gray-500" value={this.state.lobbyTime} type="number" onChange={(e) => {
                        this.setState({ lobbyTime: Math.min(e.target.value) });
                    }} />
                    <p><b>Video time (secs):</b></p>
                    <input className="w-full mb-4 px-3 py-2 rounded border-gray-200 border focus:border-gray-500" value={this.state.roundTime} type="number" onChange={(e) => {
                        this.setState({ roundTime: Math.min(e.target.value) });
                    }} />
                    <p><b>Break time (secs):</b></p>
                    <input className="w-full mb-4 px-3 py-2 rounded border-gray-200 border focus:border-gray-500" value={this.state.breakTime} type="number" onChange={(e) => {
                        this.setState({ breakTime: Math.min(e.target.value) });
                    }} />
                    <br />
                    <p><b>Social media info:</b></p>
                    {
                        Object.keys(socialInfoServices).map(service => {
                            const i = socialInfoServices[service];
                            const checked = this.state.social.includes(service);
                            return (
                                <div className="mt-4">
                                    <input type="checkbox" className="border-gray-200" checked={this.state.social.includes(service)} onChange={(e) => {
                                        const social = this.state.social;
                                        if(e.target.checked){
                                            if(!checked) social.push(service);
                                        } else {
                                            if(checked) social.splice(social.indexOf(service), 1);
                                        }
                                        this.setState({ social });
                                    }} />
                                    <img className="w-6 mr-4 ml-4 inline" src={i.image} />
                                    <p className="inline">{i.displayName}</p>
                                </div>
                            );
                        })
                    }
                    <br />
                    <button className="w-full mb-4 px-3 py-3 bg-blue-600 shadow hover:shadow-xl hover:bg-blue-500 mt-4 text-xl font-medium text-white font-medium text rounded" onClick={this.createEvent}>Create event</button>
                    <hr className="my-10" />
                    <button className="w-full text-blue-600 text-xl font-medium " onClick={this.clearFinishedEvents}>Clear finished events</button>
                </div>
            </div>
        );
    }
}

AdminPage.propTypes = {

};

export default AdminPage;