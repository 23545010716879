import React from 'react';
import Navbar from './Navbar';

const Page404 = () => {
    return ( 
        <div>
            <Navbar />

            <div className="mt-4 max-w-3xl mx-auto px-6">
                <p className="text-4xl font-semibold">404. Page not found.</p>
                <p className="text-lg text-gray-500 mt-2">We couldn't find that page.</p>
            </div>

        </div>
     );
}
 
export default Page404;