import React from "react";
import PropTypes from "prop-types";
import firebase from "firebase";
import "firebase/functions";
import {
    Tooltip,
} from "react-tippy";
import "react-tippy/dist/tippy.css"

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import moment from "moment";
import { SyncLoader } from "react-spinners";
import Loader from "./Loader";
import { toast } from "react-toastify";


class Scheduled extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: "",
            loading: false,
            reminderDropdownOpen: false
        };
        this.functions = firebase.functions();
    }

    scheduleReminder = () => {
        if (!isValidPhoneNumber(this.state.phone)) return;
        this.setState({ loading: true });
        const fn = this.functions.httpsCallable("registerTextReminder");
        fn({
            roomId: this.props.match.params.roomCode,
            phone: this.state.phone
        }).then((resp) => {
            this.setState({ loading: false });
            if (resp.data.status == "success") {
                toast.success("Text reminder set!");
                return;
            }
            toast.error("Could not set reminder");
            console.error(resp.data);
        });
    }

    render() {
        const { data } = this.props;
        const startTime = moment(data.startTime.toDate());
        const validPhone = isValidPhoneNumber(this.state.phone);
        if (this.state.loading) {
            return <Loader />
        }
        return (
            <div className="mx-auto py-20 px-6 max-w-xl">
                <div className="flex justify-center">
                    <div className="text-center">
                        <SyncLoader color={"#63b3ed"} size={20} margin={6} />
                        <p className="text-3xl text-black leading-none font-medium mt-12">Getting ready to start!</p>
                        <p className="text-lg text-gray-600 mt-3 font-light">{data.displayName} is starting at {startTime.format("h:mm A [on] MMM Do, YYYY")}.</p>

                        <div className="p-5 rounded-lg shadow-xl border mt-16">
                            <button className="text-xl hover:text-gray-700 hover:underline text-gray-600 leading-none font-medium" onClick={() => this.setState({ reminderDropdownOpen: !this.state.reminderDropdownOpen })}>
                                <span className="mr-2">Get a text reminder </span>
                                ↓
                            </button>

                            {this.state.reminderDropdownOpen ? (
                                <div>
                                    <p className="text-lg text-gray-500 mt-3 mb-2 font-light w-full flex justify-center">Enter your phone number below and we'll text you before the event starts!</p>
                                    <PhoneInput placeholder="Phone number" value={this.state.phone} defaultCountry="US" onChange={(val) => {
                                        this.setState({ phone: val });
                                    }} />
                                    <button className={`w-full px-2 py-2 shadow mt-6 text-md font-medium text-white font-medium text rounded ${validPhone ? "hover:bg-blue-500 bg-blue-600" : "bg-blue-200"}`} onClick={this.scheduleReminder}>
                                        {validPhone ? "Remind me" : "Enter a phone number"}
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Scheduled.propTypes = {

};

export default Scheduled;